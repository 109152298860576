@tailwind base;
@tailwind components;
@tailwind utilities;
@import './variables';

button, input {
  outline: none;
}

@layer base {
  html {
    font-family: var(--font-family);
    color: var(--black);
    background: var(--light-100);
  }
}